:root {
  --main-color: #f6f6f7;
  --black-color: #000;
  --white-color: #fff;
  --title-color: #202223;
  --text-color: #6d7175;
  --primary-font: "Jost", sans-serif;
  --theme-color-prime: #0060aa;
  --background-transparent: transparent;
}
* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  list-style-type: none;
  text-decoration: none;
}
body {
  /* background: var(--main-color); */
  width: 100%;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none !important;
  color: inherit;
}
a:hover {
  text-decoration: none !important;
  color: inherit;
}
.sidebar {
  width: 260px;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  background: var(--white-color);
  z-index: 100;
  transition: width 300ms;
  border-right: 1px solid #dbdddf;
}
.sidebar-brand {
  height: 90px;
  padding: 1rem 0rem 1rem 2rem;
  color: var(--title-color);
  font-family: var(--primary-font);
  text-transform: uppercase;
}
.sidebar-brand span {
  display: inline-block;
  padding-right: 1rem;
}
.sidebar-menu {
  margin-top: 1rem;
}
.sidebar-menu ul {
  height: 450px;
  overflow-y: scroll;
  padding-left: 0;
  overflow-x: hidden;
}
.sidebar-menu li {
  width: 100%;
  margin-bottom: 10px;
  padding-left: 15px;
  padding-right: 0;
}

.admin-logo {
  height: 20px;
}
.sidebar-menu a {
  padding-left: 0;
  display: block;
  color: var(--title-color);
  font-size: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: var(--primary-font);
  text-decoration: none;
}
.sidebar-menu a.active {
  color: var(--title-color);
  background: #edeeef;
  border-radius: 4px;
}
.sidebar-menu .active {
  background: green;
  color: red;
}

.sidebar-menu a:hover {
  color: var(--title-color);
  background: #edeeef;
  border-radius: 4px;
}
.sidebar-menu li a span {
  vertical-align: top;
}
.sidebar-menu li a span:first-child {
  font-size: 20px;
  padding-right: 8px;
}
.sidebar-menu ul .logout-menu {
  position: absolute;
  bottom: 0px;
}
#nav-toggle:checked + .sidebar {
  width: 70px;
}
#nav-toggle:checked + .sidebar .sidebar-brand,
#nav-toggle:checked + .sidebar li {
  padding-left: 1rem;
  text-align: center;
}
#nav-toggle:checked .sidebar li a {
  padding-left: 5px;
}
#nav-toggle:checked + .sidebar .sidebar-brand h2 span:last-child,
#nav-toggle:checked + .sidebar li a span:last-child {
  display: none;
}
#nav-toggle:checked ~ .main-content {
  margin-left: 70px;
}
#nav-toggle:checked ~ .main-content header {
  width: calc(100% - 70px);
  left: 70px;
}
.main-content {
  transition: margin-left 300ms;
  margin-left: 260px;
}
header {
  background: var(--white-color);
  display: flex;
  justify-content: space-between;
  padding: 6px 20px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 260px;
  width: calc(100% - 260px);
  top: 0;
  z-index: 100;
  transition: left 300ms;
}
header h2 {
  color: var(--main-color);
  font-weight: 400;
}
header label {
  cursor: pointer;
  vertical-align: middle;
}
header label span {
  font-size: 1.7rem;
  padding-right: 1rem;
  color: #5c5f62;
}
.user-wrapper {
  display: flex;
  height: 3.5rem;
  align-items: center;
}

.user-wrapper button img {
  margin-right: 6px;
}

.user-wrapper .dropbtn {
  margin: 14px 0;
}

.user-wrapper small {
  display: inline-block;
  color: var(--title-color);
  font-family: var(--primary-font);
  padding-left: 5px;
}
main {
  margin-top: 45px;
  padding: 2rem 1.5rem;
  background: #f6f6f7;
  min-height: calc(100vh - 45px);
}
.user-wrapper button {
  border: none;
  background: transparent;
  display: block;
  padding: 10px 0px;
  align-items: center;
}
.dropdown {
  float: right;
  position: relative;
  display: inline-block;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--white-color);
  min-width: 210px;
  padding: 8px 5px;
  overflow: auto;
  right: 0;
  z-index: 1;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.3125rem #1718180d, 0 0.0625rem 0.125rem #00000026;
}
.dropdown-content a {
  color: var(--title-color);
  font-family: var(--primary-font);
  padding: 8px 10px;
  text-decoration: none;
  font-weight: 500;
  font-size: 15px;
  display: block;
  align-items: center;
}
.dropdown-content a:hover {
  color: var(--title-color);
  background: #edeeef;
  border-radius: 4px;
}
.dropdown-content a i {
  font-size: 22px;
  vertical-align: middle;
}
.show {
  display: block;
}
.dashboardcard {
  padding: 1.25rem;
  background: var(--white-color);
  border-radius: 0.5rem;
  box-shadow: 0 0 0.3125rem #1718180d, 0 0.0625rem 0.125rem #00000026;
  font-family: var(--primary-font);
  margin-bottom: 20px;
}
.dashboardcard h2 {
  color: var(--title-color);
}
.dashboardcard h2 span {
  float: right;
}
.dashboardcard h2 span a i {
  color: #5c5f62;
  font-size: 20px;
  vertical-align: top;
}
.dashboardcard h6 {
  color: var(--text-color);
}
.infos-table {
  width: 100%;
  background: var(--white-color);
  padding: 20px;
  border-radius: 10px;
  font-family: var(--primary-font);
}
.dataTables_length {
  width: 50% !important;
  float: left;
  margin-bottom: 20px;
}
.dataTables_filter {
  width: 50% !important;
  float: left;
  margin-bottom: 20px;
}
.dataTables_info {
  display: none;
}
.dataTables_paginate {
  display: none;
}
.dataTables_length label {
  text-transform: capitalize;
}
.dataTables_length label select {
  outline: none;
  border: 1px solid #c9cccf;
  border-radius: 4px;
  padding: 3px 7px;
  color: var(--text-color);
}
.dataTables_filter label input[type="search"] {
  outline: none;
  border: 1px solid #c9cccf;
  border-radius: 4px;
  padding: 5px 12px;
  color: var(--text-color);
}
.dataTable {
}
.dataTable thead tr th {
  font-weight: 400;
  color: var(--title-color);
}
.dataTable tbody tr td {
  color: var(--text-color);
}
.dataTable tbody tr td a {
  color: var(--text-color);
}
.table-signature-img img {
  width: 100px;
}
.table-profile-img img {
  width: 50px;
}
.pages-headers {
  margin-bottom: 30px;
}
.pages-headers h2 {
  font-family: var(--primary-font);
  font-size: 25px;
}
.pages-headers h2 a {
  float: right;
  margin-left: 10px;
}
.theme-cta {
  font-family: var(--primary-font);
  color: var(--white-color);
  border-radius: 4px;
  background: var(--theme-color-prime);
  font-size: 15px;
  padding: 6px 15px 7px 15px;
}
.theme-cta:hover {
  color: var(--white-color);
}
.form-white-bg {
  width: 100%;
  background: var(--white-color);
  padding: 20px;
  border-radius: 10px;
}
.theme-form {
  margin-bottom: 15px;
  width: 100%;
  background: var(--white-color);
  font-family: var(--primary-font);
}
.theme-form label {
  width: 100%;
  margin-bottom: 4px;
  color: var(--title-color);
  text-transform: capitalize;
}
.theme-form input {
  width: 100%;
  border: none;
  outline: none;
  padding: 0px;
  font-size: 14px;
  border: 1px solid #d9d9d9;
  color: var(--text-color);
  border-radius: 5px;
  padding: 8px 12px 8px 12px;
}
.theme-form select {
  width: 100%;
  border: none;
  outline: none;
  padding: 0px;
  font-size: 14px;
  border: 1px solid #d9d9d9;
  color: var(--text-color);
  border-radius: 5px;
  padding: 8px 12px 8px 12px;
}
.theme-form-add {
  float: right;
  font-family: var(--primary-font);
}
.theme-form textarea {
  width: 100%;
  border: none;
  outline: none;
  padding: 0px;
  font-size: 14px;
  border: 1px solid #d9d9d9;
  color: var(--text-color);
  border-radius: 5px;
  padding: 8px 12px 8px 12px;
}
.theme-form button {
  font-family: var(--primary-font);
  color: var(--white-color);
  border-radius: 4px;
  background: var(--theme-color-prime);
  font-size: 15px;
  padding: 6px 25px 7px 25px;
  outline: none;
  border: none;
}
.theme-form-header {
  margin-bottom: 30px;
}
.theme-form-header h2 {
  font-family: var(--primary-font);
  font-size: 25px;
  color: var(--title-color);
}
.theme-form-header h2 a {
  border: 1px solid #c9cccf;
  border-radius: 4px;
  padding: 1px 6px;
  font-size: 22px;
  margin-right: 8px;
  color: var(--text-color);
}
.theme-form img {
  margin-top: 5px;
}
.form-signature {
  width: 200px;
}
.form-profile {
  width: 100px;
}
body::-webkit-scrollbar {
  width: 3px;
}
body::-webkit-scrollbar-track {
  background: #f1f1f1;
}
body::-webkit-scrollbar-thumb {
  background: #888;
}
body::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.table-responsive::-webkit-scrollbar {
  height: 8px;
}
.table-responsive::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.table-responsive::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}
.table-responsive::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.multiselect {
  width: 100%;
}
.selectBox {
  position: relative;
}
.selectBox select {
  width: 100%;
}
.overSelect {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  font-family: var(--primary-font);
}
#checkboxes {
  display: none;
  border: 1px #dadada solid;
  padding: 10px;
}
#checkboxes label {
  display: block;
  color: var(--text-color);
  font-size: 14px;
}
#checkboxes label input {
  width: auto;
  margin-right: 10px;
}
.login-content {
  width: 100%;
  background: var(--main-color);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
.authenticity-section {
  width: 100%;
  background: var(--main-color);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
.authenticity-table {
  font-family: var(--primary-font);
}
.authenticity-table img {
  text-align: center;
  margin: 0 auto;
  display: block;
  margin-bottom: 10px;
}
.authenticity-table h3 {
  text-align: center;
  margin-bottom: 15px;
}
.authenticity-table table {
  width: 100%;
}
.authenticity-table table tr {
}
.authenticity-table table tr td {
  border: 1px solid var(--main-color);
  padding: 10px;
}
.authenticity-table table tr td span {
  color: var(--white-color);
  text-transform: uppercase;
  padding: 1px 20px;
}
.authenticity-table table tr td .valid {
  background: #387fff;
}
.authenticity-table table tr td .invalid {
  background: #ff5338;
}
embed {
  width: 100%;
  height: 650px;
  overflow: hidden;
  scroll: none;
}

@media only screen and (max-width: 1200px) {
  .sidebar {
    width: 70px;
  }
  .sidebar .sidebar-brand,
  .sidebar li {
    padding-left: 1rem;
    text-align: center;
  }
  .sidebar li a {
    padding-left: 0rem;
  }
  .sidebar .sidebar-brand h2 span:last-child,
  .sidebar li a span:last-child {
    display: none;
  }
  .main-content {
    margin-left: 70px;
  }
  .main-content header {
    width: calc(100% - 70px);
    left: 70px;
  }
}

@media only screen and (max-width: 768px) {
  .sidebar {
    left: -100%;
  }
  header h2 {
    display: flex;
    align-content: center;
    margin-top: 10px;
  }
  header h2 label {
    display: inline-block;
    text-align: center;
    background: var(--main-color);
    padding-right: 0rem;
    margin-right: 1rem;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  header h2 span {
    text-align: center;
    padding-right: 0rem;
  }
  header h2 {
    font-size: 1.1rem;
  }
  .main-content {
    width: 100%;
    margin-left: 0rem;
  }
  header {
    width: 100% !important;
    left: 0 !important;
  }
  #nav-toggle:checked + .sidebar {
    left: 0 !important;
    z-index: 100;
    width: 345px;
  }
  /*#nav-toggle:checked + .sidebar:hover {width:  345px;z-index: 200;}*/
  #nav-toggle:checked + .sidebar .sidebar-brand,
  #nav-toggle:checked + .sidebar li {
    padding-left: 2rem;
    text-align: left;
  }
  #nav-toggle:checked + .sidebar li a {
    padding-left: 1rem;
  }
  #nav-toggle:checked + .sidebar .sidebar-brand h2 span:last-child,
  #nav-toggle:checked + .sidebar li a span:last-child {
    display: inline;
  }
  #nav-toggle:checked ~ .main-content {
    margin-left: 0rem !important;
  }
  .dataTables_length {
    width: 100% !important;
    text-align: left !important;
  }
  .dataTables_filter {
    width: 100% !important;
    text-align: left !important;
  }
  main {
    padding: 2rem 0.5rem;
  }
  .mobilerows {
    width: 100%;
    --bs-gutter-x: 0 !important;
  }
}

@media only screen and (max-width: 560px) {
  .cards {
    grid-template-columns: 100%;
  }
  .user-wrapper img {
    margin-right: 0;
  }
  /*.user-wrapper div{display: none;}*/
  .authenticity-table table tr td {
    padding: 6px;
    font-size: 15px;
  }
}

@media only screen and (max-width: 425px) {
  .pages-headers h2 {
    font-size: 20px;
  }
  .theme-cta {
    font-size: 12px;
    padding: 6px 10px 7px 10px;
  }
}

.delete-btn {
  background: none;
  border: none;
}
